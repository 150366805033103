/* You can add global styles to this file, and also import other style files */
/*
Blue = Cerulean = #4484CE
Legend
1. Columns
2. Main Menu
3. Button
4. Wrapper
5. Dashboard
6. Forms
7. Search Tables
8. Notification Letters
XX. Print
*/

@import url("reset.css");
/*@import "@angular/material/prebuilt-themes/pink-bluegrey.css";*/



.debug {
    display: none !important;
}

* {
    font-family: Roboto, "Helvetica Neue", 'Arial', sans-serif;
}

/* -------------------- 1. Columns -------------------- */
.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.row.gutters {
    margin-left: -2%;
}
div[class^="col"] {
    box-sizing: border-box;
}
.gutters > div[class^="col"] {
    margin-left: 2%;
}
.col {
    flex: 1;
}

.col--size-2 {
    flex: 2;
}

.col--size-3 {
    flex: 3;
}
.col--size-4 {
    flex: 4;
}
.col--size-5 {
    flex: 5;
}
.col--size-6 {
    flex: 6;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled), .mat-option.mat-active {
    background-color: #4484ce !important;
    color: white !important;
}

/* -------------------- 2. Main Menu -------------------- */
#main-navigation {
    width: 100%;
    background-color: #efefef;
    border-bottom: 3px solid #4484ce;
    flex: 0 1 auto;
}
.menu {
    display: inline-block;
}
.menu.menu--sub {
    display: none;
}
.menu.menu--main > li {
    display: block;
    float: left;
    position: relative;
}
.menu.menu--main > li .menu-item {
    padding: 30px;
    background-color: #efefef;
    box-sizing: border-box;
    display: inline-block;
    outline: 0;
    font-size: 22px;
    min-width: 130px;
    text-align: center;
    text-decoration: none;
    line-height: 1.2;
    color: black;
}
.menu.menu--main > li:hover > .menu-item, .menu.menu--main > li > .menu-item:focus {
    cursor: pointer;
    color: #4484ce;
}
.menu.menu--main > li:hover .menu.menu--sub, .menu.menu--main > li:active .menu.menu--sub {
    display: block;
}
.menu.menu--main > li .menu.menu--sub {
    position: absolute;
    top: 100%;
    width: 200px;
    box-shadow: 5px 5px 15px -3px rgba(0,0,0,0.3);
    z-index: 101;
}
.menu.menu--main > li .menu.menu--sub .menu-item, .menu.menu--pop-up .menu-item {
    font-size: 16px;
    background-color: #efefef;
    padding: 10px 20px;
    text-align: left;
}
.menu.menu--main > li .menu.menu--sub .menu-item {
    width: 200px;
}
.menu.menu--pop-up .menu-item {
    width: 250px;
}
.menu.menu--main > li .menu.menu--sub .menu-item:hover, .menu.menu--pop-up .menu-item:hover {
    color: #4484ce;
    cursor: pointer;
}
.menu.menu--main > li .menu.menu--sub li:last-child .menu-item {
    border-bottom: 0;
}
.menu.menu--pop-up {
    text-align: left;
}

/* -------------------- 3. Button -------------------- */
button {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
}
.button {
    display: block;
    color: white;
    background-color: #a9a9a9;
    border: 0;
    border-radius: 5px;
    text-decoration: none;
    padding: 20px;
    width: auto;
    text-align: center;
    text-transform: uppercase;
    outline: 0;
}
.button:hover, .button:focus {
    background-color: #4484ce;
    cursor: pointer;
}

/* -------------------- 4. Wrapper -------------------- */
#main-navigation .wrapper, #content .wrapper {
    max-width: 1500px;
    margin: 0 auto;
}
#content .wrapper {
    padding: 75px 20px;
}
/* -------------------- 5. Dashboard -------------------- */
.dashboard .col mat-card {
    max-width: 150px;
    margin: 0 auto;
}
.dashboard .col button, .dashboard .col .column-label {
    margin: 0 auto 16px;
    text-align: center;
}
.dashboard .col button {
    width: 100%;
}

/* -------------------- 6. Forms -------------------- */

.mat-form-field {
    width: 100%;
}
.mat-form-field-infix {
    width: auto !important;
}
.mat-card-actions {
    text-align: right;
}
.mat-card.deck {
    margin-bottom: 15px;
}
.mat-card.small-form {
    max-width: 470px;
    margin: 0 auto;
}
.mat-card.medium-form {
    max-width: 990px;
    margin: 0 auto;
}
.mat-list .list-actions {
    margin-left: auto;
}
.mat-list .list-actions button {
    margin-left: 10px;
}
.mat-list .list-actions button:first-child {
    margin-left: 0;
}
.mat-table {
    width: 100%;
    vertical-align: middle;
}
.mat-table * {
    vertical-align: middle;
    border-bottom-width: 0px !important;
}
h1.title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}
.mat-table .mat-form-field-wrapper {
    padding-bottom: 0;
}
/* .manage-table .mat-table .mat-cell {
    padding: 5px;
    text-align: center;
} */
div.owner-title {
    margin-top: 5px;
    margin-bottom: 5px;
}
div.owner-title .col {
    margin-top: auto;
    margin-bottom: auto;
}
div.owner-title p {
    font-size: 16px;
}

/* -------------------- X. List Dialog -------------------- */
.list-dialog-list .mat-list-item button {
    margin-left: auto;
}
.list-dialog h2 {
    margin-bottom: 0;
}
.list-dialog-list .mat-list-item .mat-form-field {
    width: 500px;
    margin-right: 15px;
}
.list-dialog .mat-dialog-content {
    overflow-x: hidden;
    overflow-y: auto;
}
.list-dialog .mat-list-item span {
    width: 500px;
}
.list-dialog .mat-dialog-actions {
    justify-content: flex-end;
}

/* -------------------- X. Menu Dialog -------------------- */
.menu-dialog h2 {
    margin-bottom: 10px;
}
.menu-dialog .mat-dialog-content .menu-dialog-menu {
    padding-top: 0
}
.menu-dialog .mat-dialog-content .menu-dialog-menu mat-list-item {
    height: 30px;
}

/* -------------------- 7. Search Table -------------------- */
.table {
    width: 100%;
    font-size: 10px;
    line-height: 1.2;
}

table.table, .table td, .table th {
    /* border: 1px solid #ddd; */
    text-align: left;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table td, .table th {
    padding: 5px 10px;
}

.table th {
    font-weight: bold;
}

/* -------------------- 8. Notification Letters -------------------- */
.notification-letters, .print-out-container {
    max-width: 818px;
    margin: 0 auto;
}
.notification-letters .notification-letter, .print-out-container .print-out {
    min-height: 1015px;
    height: auto;
    width: 818px;
    box-shadow: 3px 3px 30px rgba(0,0,0,0.3);
    margin-bottom: 20px;
    padding: 60px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 1.2;
}
.print-out-container .print-out.report {
    height: auto;
    min-height: 1038px;
}
.notification-letters .notification-letter.report {
    height: auto;
}
.print-out-container p, .notification-letters p, .print-out-container ul, .notification-letters ul {
    margin-bottom: 15px;
}
.print-out-container ul, .notification-letters ul {
    margin-left: 24px;
}
.print-out-container ul li, .notification-letters ul li {
    margin-bottom: 0;
}
.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.underline {
    text-decoration: underline;
}
.bold-underline {
    font-weight: bold;
    text-decoration: underline;
}
.print-out-container .bold-underline, .notification-letters .bold-underline {
    text-transform: uppercase;
}

/* -------------------- XX. T-158s -------------------- */
.t-158s, .print-out-container {
    max-width: 820px;
    margin: 0 auto;
}
.t-158s .t-158 {
    position: relative;
    height: 792px;
    width: 820px;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 1.2;
}
.t-158s > :last-child .t-158 {
    margin-bottom: 0;
}
/* -------------------- XX. T-158s -------------------- */
.list .col {
    text-align: center;
}
.list .col:first-child {
    text-align: left;
}
.list .col:last-child {
    text-align: right;
}
.list .col:last-child button {
    margin-left: 5px;
}
.list .col:last-child button:first-child {
    margin-left: 0;
}
.list {
    margin-bottom: 5px;
}
.list.list-title {
    margin-bottom: 10px;
}
.list.list-title p {
    font-size: 18px;
}
/* -------------------- XX. Reports -------------------- */
.report .col {
    text-align: center;
    font-size: 13px;
}
.report .col:first-child p {
    text-align: left;
}
.report .col:last-child p  {
    text-align: right;
}
.report p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
}
.report .title {
    font-size: 16px;
    margin-bottom: 5px;
}
.report .border-bottom, .statement .border-bottom {
    border-bottom: 2px solid black;
    margin-bottom: 10px;
}
.report .border-bottom p, .statement .border-bottom p {
    margin-bottom: 2px;
}
.report.report--customer-listing .col p {
    text-align: left;
}

/* -------------------- XX. Statements -------------------- */
.col.border-top {
    border-top: 1px solid black;
}
.left-center-right .col {
    text-align: center;
}
.left-center-right .col:first-child {
    text-align: left;
}
.left-center-right .col:last-child {
    text-align: right;
}
.statement .border-bottom .col {
    text-align: center;
}
p.marginless {
    margin-bottom: 5px;
}
.text-right {
    text-align: right !important;
}
.text-right p {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-left p {
    text-align: left;
}
.text-center {
    text-align: center;
}
.statements .margin-bottom {
    margin-bottom: 30px;
}
.statements .margin-top {
    margin-top: 30px;
}
.statements .border-top {
    border-top: 1px solid black;
}
.report .row.margin-bottom {
    margin-bottom: 10px;
}
/* -------------------- XX. Print -------------------- */
.pagebreak {
    page-break-before: always;
}
@media print {
    div#main-navigation {
        display: none;
    }
    div#content > .wrapper {
        padding: 0;
        max-width: none;
    }
    button.print-button {
        display: none;
    }
    div.notification-letters div.notification-letter, div.print-out-container div.print-out {
        box-shadow: none;
        min-height: none;
        padding: 0;
    }
    div.print-out.court-order {
        transform: scale(1.3);
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
    }
    .no-print {
        display: none !important;
    }
    .no-margins-in-print {
        margin: 0;
    }
    .no-padding-in-print {
        margin: 0;
    }
}
